<template>
  <div class="full-width-container">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <!-- CENTER THE BUTTONS -->
    <div class="button-container">
      <b-button class="shipping" @click="movement('shipping')">Envios</b-button>
      <b-button class="autoPrice" @click="movement('material')">Material</b-button>
    </div>
    <hr />

    <div v-if="this.page == 'shipping'">
      <!-- Seção para selecionar datas -->
      <h1>Envios</h1>
      <div class="row">
        <div class="col">
          <h5>Selecionar Período</h5>
          <div class="form-group">
            <label for="dataInicial">Data Inicial:</label>
            <input type="date" v-model="dateInitial" class="form-control" id="dataInicial" />
          </div>
          <div class="form-group">
            <label for="dataFinal">Data Final:</label>
            <input type="date" v-model="dateFinal" class="form-control" id="dataFinal" />
          </div>
          <div class="form-group">
            <button class="btn btn-primary" style="margin-top: 10px" @click="getShippingReportInfoF">Pesquisar</button>
          </div>
        </div>
      </div>

      <!-- Seção de Gráficos -->
      <div class="row">
        <!-- Gráfico Total -->
        <div class="col-md-6" v-if="totalGraph && totalGraph.total">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Gráfico Encomendas</h5>
              <apexchart type="donut" height="350" :options="totalChartOptions" :series="totalChartSeries" />
            </div>
          </div>
        </div>

        <!-- Gráfico Grátis -->
        <div class="col-md-6" v-if="freeGraph && freeGraph.total">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Gráfico FreeShipping</h5>
              <apexchart type="donut" height="350" :options="freeChartOptions" :series="freeChartSeries" />
            </div>
          </div>
        </div>
      </div>

      <!-- Seção de Totais de Pagamentos -->
      <div class="row mt-4" v-if="totalPayment">
        <div class="col-md-3" v-for="(value, key) in totalPayment" :key="key">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{{ key.toUpperCase() }}</h5>
              <p class="card-text">Total: €{{ value }}</p>
            </div>
          </div>
        </div>
        <!-- Exibir o total de todos os pagamentos -->
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Total Geral</h5>
              <p class="card-text">Total: €{{ totalSum }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Total Clientes</h5>
              <p class="card-text">Total: €{{ totalClientPayed }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Seção da Lista de Encomendas -->
      <div class="row mt-4">
        <div class="col">
          <h3>Lista de Encomendas</h3>
          <b-table striped hover :items="orders" :fields="orderFields" small class="custom-table">
            <template #cell(id)="data">
              <b>{{ data.item.id }}</b>
            </template>
            <template #cell(tracking_number)="data">
              {{ data.item.tracking_number }}
            </template>
            <template #cell(client_company_choice)="data">
              {{ data.item.client_company_choice }}
            </template>
            <template #cell(final_company)="data">
              {{ data.item.final_company }}
            </template>
            <template #cell(client_payed)="data"> €{{ data.item.client_payed }} </template>
            <template #cell(final_price)="data"> €{{ data.item.final_price }} </template>
            <template #cell(real_weight)="data"> {{ data.item.real_weight }}kg </template>

            <!-- Handling null values for prices -->
            <template #cell(prices)="data">
              <b>CTT:</b> {{ data.item.prices?.ctt ? `€${data.item.prices.ctt}` : 'N/A' }}<br />
              <b>CTT Expresso:</b> {{ data.item.prices?.ctt_expresso ? `€${data.item.prices.ctt_expresso}` : 'N/A'
              }}<br />
              <b>DHL:</b> {{ data.item.prices?.dhl ? `€${data.item.prices.dhl}` : 'N/A' }}<br />
              <b>FedEx:</b> {{ data.item.prices?.fedex ? `€${data.item.prices.fedex}` : 'N/A' }}
            </template>

            <!-- Handling null values for measurements -->
            <template #cell(measurements)="data">
              <b>Altura:</b>
              {{
                data.item.measurements?.height && data.item.measurements.height !== '0'
                  ? `${data.item.measurements.height}cm`
                  : 'N/A'
              }}<br />
              <b>Largura:</b>
              {{
                data.item.measurements?.width && data.item.measurements.width !== '0'
                  ? `${data.item.measurements.width}cm`
                  : 'N/A'
              }}<br />
              <b>Comprimento:</b>
              {{
                data.item.measurements?.length && data.item.measurements.length !== '0'
                  ? `${data.item.measurements.length}cm`
                  : 'N/A'
              }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div v-if="this.page == 'material'">
      <h1>Material</h1>
      <div class="row">
        <div class="col">
          <h5>Selecionar Período</h5>
          <div class="form-group">
            <label for="dataInicial">Data Inicial:</label>
            <input type="date" v-model="dateInitial" class="form-control" id="dataInicial" />
          </div>
          <div class="form-group">
            <label for="dataFinal">Data Final:</label>
            <input type="date" v-model="dateFinal" class="form-control" id="dataFinal" />
          </div>
          <div class="form-group">
            <button class="btn btn-primary" style="margin-top: 10px" @click="getMaterialReportInfoF">Pesquisar</button>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Gráfico Trans -->
        <div class="col-md-6" v-if="material_trans && material_trans.total">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Gráfico Material Transporte</h5>
              <apexchart type="donut" height="350" :options="transChartOptions" :series="transChartSeries" />
            </div>
          </div>
        </div>

        <!-- Gráfico Emb -->
        <div class="col-md-6" v-if="material_emb && material_emb.total">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Gráfico Material Embalagem</h5>
              <apexchart type="donut" height="350" :options="embChartOptions" :series="embChartSeries" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Introduzir totais -->
      </div>
      <div class="row">
        <div class="col">
          <h3>Lista de Material</h3>
          <b-table striped hover :items="material" :fields="materialFields" small class="custom-table">
            <template #cell(name)="data">
              <b>{{ data.item.name }}</b>
            </template>
            <template #cell(sku)="data">
              {{ data.item.sku }}
            </template>
            <template #cell(stock)="data">
              {{ data.item.stock }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';

export default {
  components: {
    apexchart: VueApexCharts,
    Loading,
  },
  data() {
    return {
      loading: false,
      // Data Inicial e Final
      dateInitial: '',
      dateFinal: '',
      // Dados para o Gráfico Total
      totalGraph: {
        total: 0,
        ctt_azul: { total: 0 },
        ctt_registado: { total: 0 },
        ctt_expresso: { total: 0 },
        dhl: { total: 0 },
        fedex: { total: 0 },
      },

      // Dados para o Gráfico Grátis
      freeGraph: {
        total: 0,
        ctt_azul: { total: 0 },
        ctt_registado: { total: 0 },
        ctt_expresso: { total: 0 },
        dhl: { total: 0 },
        fedex: { total: 0 },
      },

      // Lista de Encomendas
      orders: [],
      // Campos para a tabela de encomendas
      orderFields: [
        { key: 'id', label: 'ID da Encomenda' },
        { key: 'tracking_number', label: 'Número de Rastreamento' },
        { key: 'client_company_choice', label: 'Empresa do Cliente' },
        { key: 'final_company', label: 'Empresa Final' },
        { key: 'client_payed', label: 'Valor Pago pelo Cliente (€)' },
        { key: 'final_price', label: 'Preço Final (€)' },
        { key: 'expected_weight', label: 'Peso Esperado (kg)' },
        { key: 'real_weight', label: 'Peso Real (kg)' },
        { key: 'prices', label: 'Preços' },
        { key: 'measurements', label: 'Medidas (cm)' },
      ],
      totalPayment: null, // Adicionado para armazenar os valores de pagamento
      page: 'shipping',

      //Material
      material_trans: {
        total: 0,
        caixa_pequena: { total: 0 },
        caixa_media: { total: 0 },
        caixa_grande: { total: 0 },
        caixa_manual: { total: 0 },
        envelope_b3: { total: 0 },
        envelope_b4: { total: 0 },
        envelope_b5: { total: 0 },
      },
      material_emb: {
        total: 0,
        saco_ctt: { total: 0 },
        saco_fedex: { total: 0 },
        saco_fedex_bolha: { total: 0 },
        fita_cola: { total: 0 },
        fita_cola_papel: { total: 0 },
        papel_bolha: { total: 0 },
        etiquetas_zebra: { total: 0 },
        pilhas: { total: 0 },
        porta_doc_ctt: { total: 0 },
        porta_doc_nacex: { total: 0 },
        porta_doc_fedex: { total: 0 },
        porta_doc_dhl: { total: 0 },
      },

      material: [],
      materialFields: [
        { key: 'name', label: 'Nome' },
        { key: 'sku', label: 'SKU' },
        { key: 'stock', label: 'Stock' },
      ],
    };
  },
  computed: {
    ...mapGetters(['getShippingReportInfo']),
    ...mapGetters(['getMaterialReportInfo']),

    // Soma total dos valores de pagamento
    totalSum() {
      if (!this.totalPayment) return 0;
      return Object.values(this.totalPayment)
        .reduce((acc, curr) => acc + parseFloat(curr), 0)
        .toFixed(2);
    },

    totalClientPayed() {
      if (!this.orders) return 0;
      return Object.values(this.orders)
        .reduce((acc, curr) => acc + parseFloat(curr.client_payed), 0)
        .toFixed(2);
    },

    // Configuração do gráfico total
    totalChartOptions() {
      return {
        labels: ['CTT Azul', 'CTT Registado', 'CTT Expresso', 'DHL', 'FedEx'],
        chart: { type: 'donut' },
        legend: { position: 'bottom' },
        title: {
          text: `Total Encomendas: ${this.totalGraph.total}`,
          align: 'center',
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
          },
        },
      };
    },
    totalChartSeries() {
      return [
        this.totalGraph.ctt_azul.total,
        this.totalGraph.ctt_registado.total,
        this.totalGraph.ctt_expresso.total,
        this.totalGraph.dhl.total,
        this.totalGraph.fedex.total,
      ];
    },

    // Configuração do gráfico grátis
    freeChartOptions() {
      return {
        labels: ['CTT Azul', 'CTT Registado', 'CTT Expresso', 'DHL', 'FedEx'],
        chart: { type: 'donut' },
        legend: { position: 'bottom' },
        title: {
          text: `Total Encomendas: ${this.freeGraph.total}`,
          align: 'center',
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
          },
        },
      };
    },
    freeChartSeries() {
      return [
        this.freeGraph.ctt_azul.total,
        this.freeGraph.ctt_registado.total,
        this.freeGraph.ctt_expresso.total,
        this.freeGraph.dhl.total,
        this.freeGraph.fedex.total,
      ];
    },

    // Configuração do gráfico material Trans
    transChartOptions() {
      return {
        labels: [
          'Caixa Pequena (13*10*10)',
          'Caixa Média (13*13*26)',
          'Caixa Grande (20*20*30)',
          'Caixa Manual',
          'Envelope B3',
          'Envelope B4',
          'Envelope B5',
        ],
        chart: { type: 'donut' },
        legend: { position: 'bottom' },
        title: {
          text: `Total Encomendas: ${this.material_trans.total}`,
          align: 'center',
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
          },
        },
      };
    },
    transChartSeries() {
      return [
        this.material_trans.caixa_pequena.total,
        this.material_trans.caixa_media.total,
        this.material_trans.caixa_grande.total,
        this.material_trans.caixa_manual.total,
        this.material_trans.envelope_b3.total,
        this.material_trans.envelope_b4.total,
        this.material_trans.envelope_b5.total,
      ];
    },

    // Configuração do gráfico material Embalagem
    embChartOptions() {
      return {
        labels: [
          'Saco CTT',
          'Saco FEDEX',
          'Saco FEDEX (Bolha)',
          'Fita Cola',
          'Fita Cola Papel',
          'Papel Bolha',
          'Etiquetas Zebra',
          'Pilhas',
          'Porta Documentos CTT',
          'Porta Documentos NACEX',
          'Porta Documentos FEDEX',
          'Porta Documentos DHL',
        ],
        chart: { type: 'donut' },
        legend: { position: 'bottom' },
        title: {
          text: `Total Produtos Embalagem: ${this.material_emb.total}`,
          align: 'center',
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
          },
        },
      };
    },
    embChartSeries() {
      return [
        this.material_emb.saco_ctt.total,
        this.material_emb.saco_fedex.total,
        this.material_emb.saco_fedex_bolha.total,
        this.material_emb.fita_cola.total,
        this.material_emb.fita_cola_papel.total,
        this.material_emb.papel_bolha.total,
        this.material_emb.etiquetas_zebra.total,
        this.material_emb.pilhas.total,
        this.material_emb.porta_doc_ctt.total,
        this.material_emb.porta_doc_nacex.total,
        this.material_emb.porta_doc_fedex.total,
        this.material_emb.porta_doc_dhl.total,
      ];
    },
  },
  methods: {
    async movement(page) {
      if (page == 'shipping') {
        await this.getShippingReportInfoF();
      } else if (page == 'material') {
        await this.getMaterialReportInfoF();
      }
      this.page = page;
    },
    async getShippingReportInfoF() {
      this.loading = true;
      try {
        await this.$store.dispatch('getReportShipping', {
          ini_date: this.dateInitial,
          fin_date: this.dateFinal,
        });
        this.totalGraph = this.getShippingReportInfo.total_graph || this.totalGraph;
        this.freeGraph = this.getShippingReportInfo.free_graph || this.freeGraph;
        this.orders = this.getShippingReportInfo.orders || [];
        this.totalPayment = this.getShippingReportInfo.total_payment || {};
      } catch (error) {
        console.error('Erro ao buscar informações:', error);
      }
      this.loading = false;
    },
    async getMaterialReportInfoF() {
      this.loading = true;
      try {
        await this.$store.dispatch('getReportMaterial', {
          ini_date: this.dateInitial,
          fin_date: this.dateFinal,
        });

        console.log(this.getMaterialReportInfo);
        this.material_trans = this.getMaterialReportInfo.products_trans || this.material_trans;
        this.material_emb = this.getMaterialReportInfo.products_emb || this.material_emb;
        this.material = this.getMaterialReportInfo.products || [];
      } catch (error) {
        console.error('Erro ao buscar informações:', error);
      }
      this.loading = false;
    },
  },
  async created() {
    let today = new Date();
    let lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    this.dateFinal = today.toISOString().split('T')[0];
    this.dateInitial = lastMonth.toISOString().split('T')[0];
    await this.getShippingReportInfoF();
  },
};
</script>
<style scoped>
.full-width-container {
  width: 100%;
  padding: 0 20px; /* Adiciona 20px de margem nas laterais */
}

.card {
  margin-top: 20px;
  border-radius: 10px;
}

.card-body {
  text-align: center;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
}

.card-text {
  font-size: 18px;
  color: #333;
}

h3 {
  margin-bottom: 20px;
}

.custom-table {
  width: 100%;
  font-size: 13px;
  margin: 0;
}

.custom-table th,
.custom-table td {
  font-size: 14px;
  text-align: left;
  padding: 10px;
}

.custom-table b {
  font-weight: bold;
}

.shipping {
  margin: 0px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.shipping:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}

.autoPrice {
  margin: 0px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.autoPrice:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>
